.raffle-container {
    margin: 4% 5% 1% 5%;
}

.raffle-title {
    display: flex;
    flex-direction: column;
}

.raffle-title-h1 {
    font-size: 4vw;
}

.raffle-info {
    font-size: 1.5vw;
}

.you-won {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 4vw;
    margin-top: 5%;

}

.hammer {
    width: 10%;
    margin-top: 5%;
}

.picked-soon {
    font-size: 1.5vw;
}

.you-lost {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    margin-top: 5%;

}

.confetti {
    width: 4%;
}

.raffle-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tickets-image {
    width: 30%;
}

.countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3%;
}

.timer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.value-label {
    font-size: 1.7vw;
    color: #dd67ff;
}

.value {
    font-size: 4vw;
}

.value-spacer {
    font-size: 4vw;
    margin-left: 1%;
    margin-right: 1%;
    /* margin: 10px; */
}

.prize-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3%;
}

.prize-img {
    height: 30%;
    width: 30%;
    border-radius: 20px;
    border: 3px solid #dd67ff;
    cursor: pointer;
}

.prize-label {
    font-size: 1.5vw;
    padding: 1%;
}

.balance {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    margin-bottom: 2%;
    align-items: center;
}

.balance-label {
    font-size: 1.5vw;
}

.balance-amount {
    font-size: 2vw;
}

.raffle-input {
    background: #323233;
    margin: 1%;
    border-radius: 10px;
    outline: none;
    border: none;
    color: #FFF;
    padding: 4%;
    font-family: 'Montserrat Normal';
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
}

.raffle-input::-webkit-input-placeholder {
    color: #FFF;
    text-align: center;
}

.raffle-input:-ms-input-placeholder {
    color: #FFF;
    text-align: center;
}

.raffle-input:-moz-placeholder {
    color: #FFF;
    text-align: center;
}

.raffle-input::-moz-placeholder {
    color: #FFF;
    text-align: center;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.buy-wrapper {
    position: relative;
    padding: 3% 10% 3% 10%;
    margin: 5%;
    display: flex;
    height: 3vw;
    width: 12vw;
    align-items: center;
    justify-content: center;
}

.buy {
    position: absolute;
    background: #dd67ff;
    border-radius: 10px;
    color: #FFF;
    height: 100%;
    width: 100%;
    font-size: 1.6vw;

    font-family: "Montserrat Bold";
    cursor: pointer;
    transition: 0.2s all;
}

.buy:hover {
    height: 110%;
    width: 110%;
    font-size: 1.8vw;

}

.claim {
    background: #dd67ff;
    border-radius: 10px;
    color: #FFF;
    font-size: 1.6vw;
    margin: 5%;
    font-family: "Montserrat Bold";
    cursor: pointer;
    padding: 1% 2% 1% 2%;
}

.checkback {
    color: #FFF;
    display: flex;
    justify-content: center;
    font-size: 2vw;
    margin-top: 5%;
}

.buy-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
    margin-top: 2%;
    text-align: center;
}

.working {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.5vw;
    margin-top: 5%;
    flex-direction: column;
    align-items: center;
}

.raffle-ended {
    display: flex;
    justify-content: center;
    font-size: 3vw;
    margin-top: 5%;
}

.better-luck {
    font-size: 1.5vw;
}

@media only screen and (max-width: 768px) {
    .checkback {
        font-size: 5vw;
    }

    .raffle-header {
        flex-direction: column;

    }

    .tickets-image {
        align-self: center;
        width: 70%;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .raffle-title-h1 {
        font-size: 9vw;
    }

    .raffle-info {
        font-size: 4.5vw;
    }

    .value-spacer {
        font-size: 12vw;
    }

    .value {
        font-size: 12vw;

    }

    .value-label {
        font-size: 4vw;
    }

    .prize-img {
        width: 70%;
        height: 70%;
        margin-top: 10%;
    }

    .prize-label {
        font-size: 4.5vw;
    }

    .balance-label {
        font-size: 5.5vw;
    }

    .balance-amount {
        font-size: 4.5vw;
    }

    .buy-form {
        width: 80%;
    }

    .raffle-input {
        font-size: 3.5vw;
    }

    .buy-wrapper {
        height: 6vw;
        width: 20vw;
    }

    .buy {
        font-size: 3.6vw;
    }

    .buy:hover {
        font-size: 3.6vw;
    }


    .raffle-ended {
        font-size: 7vw;
    }

    .picked-soon {
        font-size: 4.5vw;
        margin-top: 5%;
    }

    .you-lost {
        font-size: 7vw;
    }

    .you-won {
        font-size: 7vw;
    }

    .confetti {
        width: 10%;
    }

    .better-luck {
        font-size: 4.5vw;
        margin-top: 5%;
    }

    .claim {
        font-size: 5.6vw;
    }

    .working {
        font-size: 5vw;
    }
}