.connect-container {
    position: absolute;
    background: #333333;
    height: 22vw;
    width: 44vw;
    right: 0%;
    top: 6vw;
    z-index: 3;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

}


.wallettype {
    position: relative;
    width: 95%;
    height: 40%;
    border: 4px solid #4d4c4c;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

}

.connect-logo {
    border-radius: 50%;
    height: 79%;
    aspect-ratio: 1/1;
    margin-left: 2%;
    cursor: pointer;
}

.connection-status {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;

}

.status {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.type {
    font-size: 1.7vw;
}

.connection-text {
    background: #4d4c4c;
    font-size: 1.2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    width: 36%;
    border-radius: 8px;
}

.disconnect-button {
    background: red;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 1.2vw;
    z-index: 5;
}

.walletconnect-modal__base {
    max-width: 400px !important;
}

@media only screen and (max-width: 768px) {

    .connect-container {
        box-shadow: 1px 0px 6px 4px #FFF;
        height: 52%;
        width: 100%;
        right: 0%;
        top: 0%;
    }

    .type {
        font-size: 2.7vw;
    }

    .connection-text {
        font-size: 2vw;
    }

}