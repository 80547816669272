.mobile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
}

.mobile-logo {
    height: 17vw;
    width: 17vw;
}

.menu-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 35vw; */
    padding-right: 1vw;
}

.burger {
    height: 10vw;
    width: 10vw;
}

.user-icon-mobile {
    height: 10vw;
    width: 10vw;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1vw;
}

.wallet-icon-mobile {
    height: 10vw;
    width: 10vw;

}

.slideout-bar {
    position: fixed;
    height: 100%;
    width: 65%;
    top: 0%;
    background: #333333;
    display: flex;
    flex-direction: column;
    transition: 0.5s all;
    z-index: 2;

}

.close-button {
    position: absolute;
    height: 11vw;
    width: 11vw;
    left: -14vw;
    top: 1%;
    border-radius: 50%;
    border: 4px solid #dd67ff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.x {
    height: 70%;
}

.mobile-connect-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10%;
}

.mobile-menu-btn {
    font-size: 3vw;
    background: #dd67ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding-right: 5%;
    padding-left: 5%;
    height: 60%;
    color: #000;
    align-items: center;
    margin-right: 5%;
    border: none;
    outline: none;
    font-family: 'Montserrat Bold';

}

.mobile-balance {
    position: absolute;
    top: 8.5%;
    font-size: 3vw;
    color: #FFF;
    right: 50%;
    transform: translateX(100%);
}

.mobile-nav {
    position: relative;
    margin-top: 12%;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    padding: 3%;
    box-sizing: border-box;
}

.header-lock-mobile {
    position: absolute;
    right: -2%;
    top: 8%;
    height: 50%;
}

.mobile-selection {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 4.5vw;
    margin-bottom: 10%;
    width: 100%;
    height: 12vw;
    border-radius: 7px;
}

.mobile-selection-icon {
    height: 6vw;
    width: 6vw;
}

.socials {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
}

.social-link {
    font-size: 3.5vw;
    margin-bottom: 10%;
    margin-right: 5%;
}

a {
    text-decoration: none;
    color: #FFF
}