@font-face {
    font-family: 'Montserrat Normal';
    src: local("Montserrat"), url("./assets/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Bold";
    src: local("Montserrat Bold"), url("./assets/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Beautiful-Freak";
    src: local("Beautiful-Freak"), url("./assets/Beautiful-Freak.otf") format("opentype");
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 5;
}

.walletconnect-modal__base {
    top: 55% !important
}

.banner-unconnected {
    color: #000;
    font-family: "Montserrat Bold";
    position: relative;
    left: 50%;
    transform: translateX(-10%);
}

.blocker {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #000;
    z-index: 10;
    color: #fff;
    justify-content: center;
    align-items: center;

}

.gooselogo-text {
    font-family: 'Beautiful-Freak';
    position: absolute;
    top: -8%;
    left: 49%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

.disclaimer {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 1.5vw;
}

.app-container {
    font-family: 'Montserrat Normal';
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 100%;


}

.btn-main {
    align-items: center;
    background-color: #dd67ff;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    padding: 0px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.4%;
    padding-bottom: 0.4%;
    text-align: center;
    vertical-align: middle;
    font-family: 'Montserrat Bold';
    font-size: 1.2vw;
}

.btn-main:hover {
    color: #fff
}

.btn-connect {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-family: 'Montserrat Bold';
    padding-left: 1%;
    padding-right: 1%;
    height: 12%;
}

.btn-save {
    height: 140%;

}

.banner-header {
    display: flex;
    flex-direction: column;
    padding: 2%;
    padding-left: 12%;
    font-size: 1vw;
    padding-right: 3%;
    /* border: 1px solid red; */

}

.image-container {
    width: 62%;
    height: auto;
    position: relative;
    overflow: hidden;
    width: 62%;
    aspect-ratio: 3/1;
    /* border:1px solid red */
}

.edit-container {
    width: 70%;
    height: 31%;
    padding-left: 12%;
    padding-right: 19%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    /* border: 1px solid red; */

}

.heading-title {
    font-family: 'Montserrat Bold';
    font-size: 2vw;
}

.row-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.text-display {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5%;
    border-radius: 100px;
    background: transparent;
    color: #fff;
    outline: none;
    padding-left: 5px;
    height: 8%;
    z-index: 2;
    padding-bottom: 5px;
    padding-right: 5px;


}

.cgf-logo {
    width: 100%;
    height: 100%;
}


.pinata-goose {
    width: 100%;
    height: 100%;
}

.flex-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /* border: 1px solid red; */
    height: 60%;
    display: flex;
    flex-direction: row;
}

.row {
    /* border: 1px solid blue;    */
    height: 100%;
    width: 100%;
    flex-direction: row;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.phone-carousel {
    height: 4%;
    width: 100%;
    background: #fff;
    color: #000;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Montserrat Bold;
    font-size: 4vw;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;

}



@media only screen and (max-width: 700px) {
    .banner-header {
        padding-left: 0%;
        font-size: 3vw;
    }

    .heading-title {
        font-size: 8vw;
    }

    .image-container {
        width: 100%;
    }

    .btn-connect {
        font-size: 3.2vw;
        height: 17%;
    }

    .btn-main {
        font-size: 3.2vw;

    }

    .edit-container {
        width: 100%;
        height: 24%;
        padding-left: 0%;
        padding-right: 0%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .btn-save {
        height: 20%;
        width: 23%;
        font-size: 3vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .caret {
        height: 37%;
    }

    .image-container {
        /* margin-top: 4%;
        width: 100%;
        height: auto;
        position: relative;
        aspect-ratio: 3/1;   */
        /* border:1px solid red */
    }

    .gooselogo-text {
        /* letter-spacing: 2px;        */
    }


}