.row-wrap-text{
    display: flex;
    flex-direction: row;
    align-items: center;
   
}
.text-input{
    border-radius: 100px;
    background: transparent;
    border: 2px solid white;
    margin-left: 10px;
    color: #fff;
    outline: none;
    padding-left: 5px; 
    height: 60%;
    width: 50%;
    font-size: 1vw;
}
.title-content{
    font-family: 'Montserrat Bold';
    font-size: 1vw;
  }
  @media only screen and (max-width: 700px) {
    .title-content{
        font-size: 4vw;
    }
    .text-input{
        width: 67%;
        height: 6vw;
        font-size: 3vw;
    }
    .row-wrap-text{
        /* margin-top: 5%;
        margin-bottom: 25%; */
    }
  }
  