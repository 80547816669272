.bubble-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 4% 5% 1% 5%;
}

@font-face {
    font-family: "Beautiful-Freak";
    src: local("Beautiful-Freak"), url("https://pond.b-cdn.net/Beautiful-Freak.otf") format("opentype");
}

/* .dashboard-container {
    position: relative;
} */

.wrapper1 {
    display: flex;
    flex-direction: row
}

.wrapper2 {
    display: flex;
    flex-direction: row;
    margin-top: 2vw;
    background-size: 100%;
}

.raffle {
    width: 48vw;
    height: 20vw;
    border-radius: 10px;
    background: #000;
    color: #fff;
    margin-right: 2vw;
    padding: 1vw;
    cursor: pointer;
    font-size: 2vw;
    background-size: cover;
}

.market {
    width: 49vw;
    height: 20vw;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 1vw;
    cursor: pointer;
    font-size: 2vw;
    background-size: cover;
}

.shop {
    width: 98%;
    height: 20vw;
    border-radius: 10px;
    background: #000;
    color: #fff;
    margin-right: 2vw;
    padding: 1vw;
    cursor: pointer;
    font-size: 2vw;
    margin-top: 2vw;
    background-size: cover;
}

.lock-icon {
    position: absolute;
    height: 4vw;
    top: 0%;
    right: 0%;
}

.missions {
    width: 56vw;
    height: 36vw;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 1vw;
    cursor: pointer;
    font-size: 2vw;
    margin-right: 2vw;
    background-size: 100%;
    position: relative;
}

.honk {
    width: 36vw;
    height: 36vw;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 1vw;
    cursor: pointer;
    font-size: 2vw;
    background-size: cover;
}

.goosemash {
    width: 32vw;
    height: 20vw;
    border-radius: 10px;
    background: #000;
    color: #fff;
    margin-right: 2vw;
    padding: 1vw;
    cursor: pointer;
    font-size: 2vw;
    background-size: cover;
}

.banner {
    width: 60vw;
    height: 20vw;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 1vw;
    cursor: pointer;
    font-size: 2vw;
    background-size: cover;
}

.subtext {
    font-size: 1.3vw;
    margin-top: 10px;

}



@media only screen and (max-width: 768px) {
    .subtext {
        font-size: 2vw;
    }

    .bubble-wrapper {
        margin: 8% 5% 1% 5%;
    }

    .raffle {
        font-size: 3.5vw;
        width: 98%;
        height: 42vw;
        margin-right: 4vw;


    }

    .shop {
        font-size: 3.5vw;
        width: 98%;
        margin-bottom: 2vw;
        height: 42vw;


    }

    .market {
        font-size: 3.5vw;
        width: 98%;
        margin-bottom: 2vw;
        height: 42vw;

    }

    .honk {
        font-size: 3.5vw;
        width: 98%;
        margin-bottom: 2vw;
        height: 42vw;

    }

    .banner {
        font-size: 3.5vw;
        width: 98%;
        height: 42vw;
    }

    .goosemash {
        font-size: 3.5vw;
        width: 98%;
        height: 42vw;
        margin-right: 4vw;
    }

    .missions {
        font-size: 3.5vw;
        width: 98%;
        height: 42vw;
        margin-right: 4vw;


    }

    .lock-icon {
        height: 9vw;
        top: 0%;
        right: 1%;
    }



}