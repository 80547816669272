.mission-container {
    position: relative;
    width: 95%;
    height: 24vw;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%;
    margin-bottom: 3%;
    font-family: "Montserrat Bold", serif !important;
}

.mission-title {
    font-size: 2.5vw;
    margin-top: 1vw;
}

.mission-title-mobile {
    font-size: 4vw;
    margin-top: 1vw;
}

.crumb-image {
    width: 20px
}

.mission-image {
 width: 25%;
    margin: 50px;
}

.mission-left {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    justify-content: space-around;
    padding-right: 20vw;
}

.mission-spacer {
    width: 30px;
}

.greyScale {
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.overLay {
    top: inherit;
    position: absolute;
    z-index: 100;
    height: 100%;
    text-align: center;
    background:rgba(0,0,0,0.5);
    width: 100%;
    align-items: center;
}

.overLayMobile {
    top: inherit;
    position: absolute;
    height: 40vw;
    text-align: center;
    background:rgba(0,0,0,0.5);
    width: 81%;
    vertical-align: middle;
    align-items: center;
}


.mission-reward {
    background: #e2b006;
    border-radius: 100px;
    width: 60%;
    height: 20%;
    display: flex;
    font-size: 1.4vw;
    margin-left: 10%;
}

.mission-right {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.card {
    opacity: 0.3;
}

.card:hover{
    opacity: 1;
}

.mission-type-image {
    width: 40%;
    border-radius: 8px;
    aspect-ratio: 1/1;
    border: 2px solid #dd67ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
}

.mission-plus {
    background: #dd67ff;
    border-radius: 50%;
    height: 20%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    border: 1px solid purple;
    cursor: pointer;
}

.mission-join-button {
    background: #dd67ff;
    width: 90%;
    height: 100%;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    color: #FFF;
    font-size: 1.3vw;
    font-family: Montserrat Bold;

}

.mission-join-button-mobile {
    background: #dd67ff;
    width: 90%;
    height: 100%;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    color: #FFF;
    font-size: 2vw;
    font-family: Montserrat Bold;

}

.overflow-mobile {
    -webkit-overflow-scrolling: touch !important;
}
