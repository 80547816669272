.honk-wrapper {
    display: flex;
    flex-direction: column;
    margin: 1% 5% 1% 5%;
}

.honk-instructions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

}

.honk-text {
    font-family: 'Montserrat Bold';
    font-size: 1.5vw;
    width: 60%;
}

.honk-goose {
    height: 23%;
    width: 23%;
}

.honk-form-wrapper {
    width: 100%;
    position: relative;
    height: 58vh;
    background: #FFF;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
}

.honk-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.honk-button-wrapper {
    position: relative;
    height: 13%;
    margin: 1%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.honk-button {
    height: 100%;
    width: 100%;
    position: absolute;
    color: #FFF;
    background: #dd67ff;
    border: none;
    cursor: pointer;
    font-family: 'Montserrat Bold';
    border-radius: 10px;
    font-size: 1.5vw;
}

.honk-button:hover {
    height: 115%;
    width: 101%;
    transition: 0.3s ease-in-out;
    font-size: 1.7vw;
}


.honk-input {
    background: #323233;
    margin: 1%;
    border-radius: 10px;
    outline: none;
    border: none;
    color: #FFF;
    padding: 1%;
    font-family: 'Montserrat Normal';
    font-size: 1.5vw;
}

.honk-input:focus {
    outline: 4px solid #dd67ff;
}

.headone {
    font-size: 4vw;
}

.headtwo {
    font-size: 2vw;
}

.top {
    height: 6%;
}

.bottom {
    height: 30%;
    resize: none;
    margin-top: 0%;

}


.honk-social {
    display: flex;
    flex-direction: row;
    height: 15%;
    justify-content: space-between;
    overflow: auto;
    align-items: center;
    overflow: hidden;
    padding-top: 4px;
    padding-bottom: 4px;


}

.social {
    margin-top: 0%;
    margin-bottom: 0%;
    height: 55%;
    width: 50%;
}



.honk-input::-webkit-input-placeholder {
    color: #FFF;

}

.honk-input:-ms-input-placeholder {
    color: #FFF;

}

.honk-input:-moz-placeholder {
    color: #FFF;

}

.honk-input::-moz-placeholder {
    color: #FFF;
}



@media only screen and (max-width: 768px) {
    .headone {
        font-size: 9vw;
    }

    .honk-text {
        font-size: 4.5vw;
        width: 100%;
    }

    .honk-instructions {
        flex-direction: column;
        align-items: center;
    }

    .honk-goose {
        display: none;
    }

    .honk-form {
        height: 97%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .honk-form-wrapper {
        height: 42vh;
        display: flex;
        align-items: center;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .honk-input {
        font-size: 4vw;
        padding: 2%;
        outline: none;
    }

    .honk-input:hover {
        outline: none;
        border: 4px solid #dd67ff;
    }

    .honk-button {
        font-size: 4vw;
    }

    .honk-button:hover {
        font-size: 4vw;
    }

    .bottom {
        height: 35%;
    }

    .social {
        height: 70%;
    }

    .top {
        width: 90%;
    }

    .bottom {
        width: 90%;
    }

    .honk-button-wrapper {
        width: 90%;
    }

    .honk-social {
        width: 95%;
    }
}