table {
    color: #FFF;
    border: 1px solid #FFF;
}

td {
    color: #FFF;
    border: 1px solid #FFF;
}

tr {
    color: #FFF;
    border: 1px solid #FFF;
}

th {
    color: #FFF;
    border: 1px solid #FFF;

}