/* TOGGLE SWITCH  */

.toggle-switch {
    position: relative;
    width: 7%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    padding: 0;
    line-height: 1.7vw;
    font-size: .7vw;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: "ON";
    text-transform: uppercase;
    padding-left: 5%;
    background-color: rgb(29, 221, 45);
    color: #fff;
}
.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-inner:after {
    content: "OFF";
    text-transform: uppercase;
    padding-right: 5%;
    background-color: rgb(255, 0, 0);
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    background: #dd67ff;
    position: absolute;
    top: 0;
    width:55%;
    bottom: 0;
    right: 47%;
    border: 0 solid #bbb;
    border-radius: 12px;
    transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
}

/* Toggle end */
.row-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.title{
    font-family: 'Montserrat Bold';
    font-size: 1vw;
}

@media only screen and (max-width: 700px) {
    .toggle-switch{
        left: 50%;
        width: 16%;
        transform: translateX(-50%);
        margin-top: 5%;
        /* margin-bottom: 25%; */
    }
    .toggle-switch-inner:before, .toggle-switch-inner:after {
        line-height: 6.7vw;
        font-size: 2vw;
    }
   
}
  