@font-face {
  font-family: "Montserrat Bold";
  src:local("Montserrat Bold"), url("../../assets/Montserrat-Bold.ttf") format("truetype");   
}
.row-wrap-picker{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.selector{
  font-family: 'Montserrat Bold';
    position: relative;
    background: #dd67ff;
    color:#000;
    border-radius: 100px;
    padding-left: 2.5%;
    padding-right: 2.5%;
    cursor: pointer;
    font-size: 1vw;
    width: 12%;
}
.title{
  font-family: 'Montserrat Bold';
  font-size: 1vw;
}

.dropdown-content{
   
    flex-direction: column;
    background: #fff;
    border-radius: 9px;
    width: 100%;
    overflow-y: scroll;
}
.dropdown-caret{
  font-size: 1.7vw;
  position: absolute;
  bottom: -30%;
  right: 3%;
}
.goose{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    margin-left:2px;
}
.goose-check{
cursor: pointer;
}
.goose-check:checked{
}

/* CHECKBOX SCROLLBAR */
/* width */
.dropdown-content::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  .dropdown-content::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-top-right-radius: 40%;
    border-bottom-right-radius:40% ;
  }
   
  /* Handle */
  .dropdown-content::-webkit-scrollbar-thumb {
    background: #dd67ff; 
    border-radius: 5px;
  }
  /* CHECKBOX SCROLLBAR */


  .goose-remove{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    background: #fff;
    color:#000;
    border-radius: 100px;
    padding-left: 1%;
    padding-right: 1%;
    cursor: pointer;
    font-family: 'Montserrat Bold';
    margin-left: 2%;
    font-size: 1vw;
    white-space: nowrap;
  }

    /* remove SCROLLBAR */
    .remove-wrapper::-webkit-scrollbar {
      width: 10px;
    }
    
    /* Track */
    .remove-wrapper::-webkit-scrollbar-track {
      background: #000; 
      /* border-top-right-radius: 40%;
      border-bottom-right-radius:40% ; */
    }
     
    /* Handle */
    .remove-wrapper::-webkit-scrollbar-thumb {
      background: #dd67ff;
      border-radius: 10px;
      border: 6px solid #000;
    }

    /* remove SCROLLBAR */
  
  .x-icon{
    height:60%;
    width:1vw
  }
  .outer-wrapper{
    position: absolute;
    display: flex;
    border-radius: 9px;
    z-index: 4;
    width:102%;
    left: 0;
    margin-top:4px;
    height: auto;
    max-height: 542%;
    overflow: hidden;

  }
  .remove-wrapper{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 76%;
  }

  @media only screen and (max-width: 700px) {
   
    .selector{
    width: 100%;
    height: 8vw;
    font-size: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .dropdown-caret{
      font-size: 9.7vw;
      position: absolute;
      bottom: -32%;
      right: 3%;
  
    }
    .outer-wrapper{
      top: 100%;
     
    }
    .dropdown-content{
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
    .row-wrap-picker{
      flex-direction: column;
    }
    .x-icon{
    height: 50%;
    width: 3vw;
    }
    .goose-remove{
      font-size: 4vw;
    }
    .remove-wrapper{
      width: 100%;
      margin-top: 3%;      
    }
    .goose{
      margin-bottom:3%
    }
    
  }
 
  
 