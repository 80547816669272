.color-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;   
    /* border: 1px solid red; */
    height: 50px;
    padding-left: 4%;
}
.title{
    font-family: 'Montserrat Bold';
    font-size: 1vw;
  }
  
.color{
    position: relative;
    width:10%;
    height:100%;
    border-radius: 6px;
    cursor: pointer;
   

}
.color:not(:last-child){
    margin-right:2%
}

@media only screen and (max-width: 700px) {
    .title{
        display: none;
    }
    .color{
        position: relative;
        height: 10vw;
        width: 10vw;    
        border-radius: 50%;
        cursor: pointer; 
    }
    .color-wrapper{
        margin-top: 7%;
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap:7.3%; 
        grid-template-areas: 
          ". . . . . ."
          ". . . . . ."; 
          padding-left: 0%;
          justify-content: center;
          align-content: center;

    }
    .color:not(:last-child){
        /* margin-right:5%;
        margin-bottom:5% */
    }
}