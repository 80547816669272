.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.logo {
    height: 6vw;
    width: 6vw;
    cursor: pointer;
}

.nav-button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: 1.2vw;
    padding: 1%;
    border-radius: 7px;
}

.header-lock-desktop {
    position: absolute;
    right: 0%;
    top: 13%;
    height: 40%;
}

.nav-icon {
    width: 1.7vw;
    height: 1.7vw;
    margin-right: 0.5vw;
}

.nav-active:hover {
    background: #333333;

}

.connect-btn {
    align-items: center;
    background-color: #dd67ff;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    padding: 0px;
    padding-top: 6%;
    padding-bottom: 6%;
    text-align: center;
    vertical-align: middle;
    font-family: 'Montserrat Bold';
    font-size: 1vw;
    white-space: nowrap;
    width: 12vw;
}

.connect-btn:hover {
    color: #FFF;
}

.button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
}

.goots-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.user-icon {
    height: 3.5vw;
    width: 3.5vw;
    border: 2px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1vw;
}

.wallet-icon {
    width: 2.5vw;
    height: 2.5vw;
}

.goots {
    position: absolute;
    top: 112%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 1vw;

}