.footer-container {
    padding: 3% 5% 3% 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: "Montserrat Normal";
    font-size: 1.2vw;

}

.terms {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.terms-link {
    text-decoration: none;
    color: #dd67ff;
}

.discord {
    border-radius: 50%;
    background: #1DA1F2;
    height: 3vw;
    width: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    position: absolute;
    margin-right: 4%;
}

.discord:hover {
    height: 3.5vw;
    width: 3.5vw;
}

.twitter:hover {
    height: 3.5vw;
    width: 3.5vw;
}

.discord-icon {
    height: 60%;
    width: 60%;
}

.twitter {
    border-radius: 50%;
    background: #7289DA;
    height: 3vw;
    width: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    position: absolute;
    margin-left: 4%;
}

.footer-link {
    display: flex;
    flex-direction: row;
    width: 20%;
    justify-content: center;
    height: 100%;
    align-items: center;
}


@media only screen and (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        font-size: 3.5vw;
        height: 19vh;
        margin-top: 10%;

    }

    .terms {
        align-items: center;
    }

    .twitter {
        height: 11vw;
        width: 11vw;
        position: relative;
        margin-left: 0%;
    }

    .footer-link {
        justify-content: space-evenly;
        width: 40%;
    }

    .discord {
        margin-right: 0%;
        height: 11vw;
        width: 11vw;
        position: relative;
    }
}