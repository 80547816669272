@font-face {
    font-family: 'Montserrat Normal';
    src: local("Montserrat"), url("./assets/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Bold";
    src: local("Montserrat Bold"), url("./assets/Montserrat-Bold.ttf") format("truetype");
}

html,
body {
    /* overflow-x: hidden; */
}

body {
    background: #000;
    color: #FFF;
    font-family: "Montserrat Bold";
    position: relative;
}

::selection {
    background: #dd67ff;
}

html {
    scroll-behavior: smooth;
}

/*SCROLLBAR */
/* width */
/* ::-webkit-scrollbar {
    width: 7px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #000;
    border-top-right-radius: 40%;
    border-bottom-right-radius: 40%;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #dd67ff;
    border-radius: 5px;
} */

/*SCROLLBAR */